import { Alert, Box, Button, Checkbox, IconButton, LinearProgress, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext';
import { tokens } from '../../../../theme';
import AddBrandAccountDialog from './addBrandAccountDialog';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { Formik } from 'formik';
import * as yup from "yup";
import YupPassword from 'yup-password';
import { Delete, RemoveCircleOutline } from '@mui/icons-material';
import AlertDialogSlide from '../../../../components/AlertDialogSlide';
import IdeasoftDialog from './ideasoftDialog';
YupPassword(yup);

function Profile() {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode);
    const { user, token, setUser } = useContext(UserContext);
    const [admins, setAdmins] = useState([]);
    const [descEdit, setDescEdit] = useState(false);
    const [showOrderComments, setShowOrderComments] = useState(user.show_order_comments === 1);
    const [desc, setDesc] = useState(user.description);
    const [descLoading, setDescLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [passwordFormLoading, setPasswordFormLoading] = useState(false);
    const [showPasswordAlert, setShowPasswordAlert] = useState(false);

    const initialValues = {
        website: user.website,
        logoSquare: user.logo_link,
        logoHorizontal: user.horizontal_logo_link,
        logoHorizontalBig: user.big_horizontal_logo_link,
        logoVertical: user.vertical_logo_link,
    }
    const passwordInitialValues = {
        oldPassword: "",
        password: "",
        passwordCheck: ""
    }

    const brandSchema = yup.object().shape({
        website: yup.string().url("Geçersiz url! (örn: https://markaniz.com/").required("zorunlu"),
        logoSquare: yup.mixed().required("zorunlu"),
        logoHorizontal: yup.mixed().required("zorunlu"),
        logoHorizontalBig: yup.mixed().required("zorunlu"),
        logoVertical: yup.mixed().required("zorunlu")
    });
    const passwordSchema = yup.object().shape({
        oldPassword: yup.string()
            .required('zorunlu'),
        password: yup.string()
            .required('zorunlu')
            .min(8, 'Şifre çok kısa - en az 8 karakter olmalı')
            .minLowercase(1, 'Şifreniz en az 1 küçük harf içermelidir')
            .minUppercase(1, 'Şifreniz en az 1 büyük harf içermelidir')
            .minNumbers(1, 'Şifreniz en az 1 rakam içermelidir')
            .minSymbols(1, 'Şifreniz en az 1 özel karakter içermelidir'),
        passwordCheck: yup.string()
            .oneOf([yup.ref('password'), null], 'Şifreler uyuşmuyor')
    });

    const updateDescription = async () => {
        setDescLoading(true);
        await axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/updateBrand?token=${token}&brandId=${user.id}`, { brand: { description: desc } })
            .catch((err) => {
                console.log("err: " + err);
                setDesc(user.description);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    user.description = desc;
                }
            });
        setDescLoading(false);
    }

    const updateShowOrderComments = (showOrderCommentsStatus) => {
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/updateBrand?token=${token}&brandId=${user.id}`, { brand: { show_order_comments: showOrderCommentsStatus } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    user.show_order_comments = showOrderCommentsStatus;
                    setShowOrderComments(showOrderCommentsStatus === 1);
                    let newUser = Object.assign({}, user);
                    setUser(newUser);
                }
            });
    }

    const handleFormSubmit = async (values) => {
        setFormLoading(true);
        var data = {
            website: values.website
        }
        if (values.logoSquare && typeof values.logoSquare !== "string") {
            await axios.post(`${process.env.REACT_APP_API_URL}api/alcago/brand/uploadBrandLogo?token=${token}`, { brandLogo: values.logoSquare }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        data.logo_path = response.data.logoPath;
                    }
                });
        }
        if (values.logoHorizontal && typeof values.logoHorizontal !== "string") {
            await axios.post(`${process.env.REACT_APP_API_URL}api/alcago/brand/uploadHorizontalLogo?token=${token}`, { horizontalLogo: values.logoHorizontal }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        data.horizontal_logo_path = response.data.logoPath;
                    }
                });
        }
        if (values.logoHorizontalBig && typeof values.logoHorizontalBig !== "string") {
            await axios.post(`${process.env.REACT_APP_API_URL}api/alcago/brand/uploadBigHorizontalLogo?token=${token}`, { bigHorizontalLogo: values.logoHorizontalBig }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        data.big_horizontal_logo_path = response.data.logoPath;
                    }
                });
        }
        if (values.logoVertical && typeof values.logoVertical !== "string") {
            await axios.post(`${process.env.REACT_APP_API_URL}api/alcago/brand/uploadVerticalLogo?token=${token}`, { verticalLogo: values.logoVertical }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        data.vertical_logo_path = response.data.logoPath;
                    }
                });
        }

        await axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/updateBrand?token=${token}&brandId=${user.id}`, { brand: data })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    user.website = values.website;
                }
            });

        setFormLoading(false);
    }
    const handlePasswordFormSubmit = async (values) => {
        setPasswordFormLoading(true);
        await axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/changePassword?token=${token}`, {
            oldPassword: values.oldPassword,
            password: values.password
        }).catch((err) => {
            console.log("err: " + err);
            if (err.response.data.status === 401) {
                setShowPasswordAlert(true);
                setTimeout(() => setShowPasswordAlert(false), 2000);
            }
        })
        setPasswordFormLoading(false);
    }

    const fetchAccounts = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getBrandAccountsWithBrandId?token=${token}&brandId=${user.id}`)
            .catch((err) => {
                console.log("err: " + err);
                setAdmins([]);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setAdmins(response.data.accounts);
                }
            });
    }

    useEffect(() => {
        fetchAccounts();
    }, [])


    const [openDialog, setOpenDialog] = useState(false);
    const handleDialogClose = () => {
        fetchAccounts();
        setOpenDialog(false);
    }

    const [openIdeasoftDialog, setOpenIdeasoftDialog] = useState(false);
    const handleIdeasoftDialogClose = () => {
        window.location.reload();
    }

    const [openRemoveAccountDialog, setOpenRemoveAccountDialog] = useState(false);
    const [selectedAccountId, setSelectedAccountId] = useState(null);
    const clickPositiveAnswer = () => {
        axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/brand/deleteBrandAccount?token=${token}&accountId=${selectedAccountId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchAccounts();
                    setSelectedAccountId(null);
                    setOpenRemoveAccountDialog(false);
                }
            });
    }
    const clickNegativeAnswer = () => {
        setSelectedAccountId(null);
        setOpenRemoveAccountDialog(false);
    }

    const handleRemoveAccountDialogClose = () => {
        fetchAccounts();
        setSelectedAccountId(null);
        setOpenRemoveAccountDialog(false);
    }

    useEffect(() => {
        if (selectedAccountId) {
            setOpenRemoveAccountDialog(true);
        }
    }, [selectedAccountId])

    return (
        <Box m="20px 30px 20px 20px">
            <AddBrandAccountDialog open={openDialog} handleClose={handleDialogClose} />
            <IdeasoftDialog open={openIdeasoftDialog} handleClose={handleIdeasoftDialogClose} brandData={{
                brand: {
                    name: user.name,
                    website: user.website
                }
            }} />
            <AlertDialogSlide
                open={openRemoveAccountDialog} handleClose={handleRemoveAccountDialogClose}
                clickPositiveAnswer={clickPositiveAnswer} clickNegativeAnswer={clickNegativeAnswer}
                positiveAnswer={"Sil"} negativeAnswer={"İptal"}
                title={"Bu yöneticiyi silmek istediğinize emin misiniz?"}
                description={"Bu yönetici ile ilişkili tüm veriler silinecektir."}
            />
            <Box display="flex">
                <Box width="50%">
                    <Header title={user.name} />
                    {descLoading && <LinearProgress color='success' sx={{ my: "20px" }} />}
                    {!descEdit &&
                        <Typography variant='h5' color={colors.greenAccent[400]} marginBottom="10px">
                            {desc}
                        </Typography>
                    }
                    {descEdit &&
                        <TextField multiline value={desc} type="text" onChange={(event) => setDesc(event.target.value)} sx={{ marginBottom: "50px", width: "100%" }} />
                    }
                </Box>
                {descEdit &&
                    <IconButton sx={{ scale: "1.5", margin: "auto 0 auto 20px" }} onClick={() => {
                        setDescEdit(!descEdit);
                        updateDescription();
                    }}>
                        <DoneIcon />
                    </IconButton>
                }
                {descEdit &&
                    <IconButton sx={{ scale: "1.5", margin: "auto 20px" }} onClick={() => { setDescEdit(!descEdit) }}>
                        <CloseIcon />
                    </IconButton>
                }
                {!descEdit &&
                    <IconButton sx={{ scale: "1.5", margin: "auto 20px" }} onClick={() => { setDescEdit(!descEdit) }}>
                        <EditIcon />
                    </IconButton>
                }

            </Box>
            <Box>
                <Checkbox
                    checked={showOrderComments}
                    size="medium"
                    sx={{
                        '&.Mui-checked': {
                            color: (theme.palette.mode === "dark" ? colors.greenAccent[500] : colors.greenAccent[200])
                        }
                    }}
                    onClick={(event) => {
                        updateShowOrderComments(showOrderComments ? 0 : 1);
                    }}
                />
                <Typography display="inline">Sipariş yorumlarını göster</Typography>
            </Box>
            <Box>
                <Box display="flex" gap="30px">
                    <Box width="80%" />
                    <Typography variant='h2' width="20%" textAlign="center">Yöneticiler</Typography>
                </Box>
                <Box display="flex" gap="30px">
                    <Box width="80%">
                        <Box paddingRight="70px">
                            <Formik
                                onSubmit={handleFormSubmit}
                                initialValues={initialValues}
                                validationSchema={brandSchema}
                            >
                                {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                                            <Typography variant='h2' gridColumn="span 4">Marka Profili</Typography>
                                            {formLoading && <LinearProgress color='success' sx={{ gridColumn: "span 4" }} />}
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label="Website"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.website}
                                                name="website"
                                                error={!!touched.website && !!errors.website}
                                                helperText={touched.website && errors.website}
                                                sx={{ gridColumn: "span 2" }}
                                            />
                                            <Box gridColumn="span 2" />
                                            <Box gridColumn="span 1">
                                                <Typography variant='h5' marginBottom="5px">Logo(1080 x 1080): </Typography>
                                                <Button component="label" style={{ textAlign: "center", padding: "0" }}>
                                                    {values.logoSquare && values.logoSquare !== "" && <img alt='square brand logo' width="100%" src={typeof values.logoSquare == "string" ? values.logoSquare : URL.createObjectURL(values.logoSquare)} />}
                                                    <input id="logoSquare" name="logoSquare" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("logoSquare", event.target.files[0]); }} hidden />
                                                </Button>
                                            </Box>
                                            <Box gridColumn="span 1">
                                                <Typography variant='h5' marginBottom="5px">Logo(350 x 300): </Typography>
                                                <Button component="label" style={{ textAlign: "center", padding: "0" }}>
                                                    {values.logoHorizontal && values.logoHorizontal !== "" && <img alt='horizontal brand logo' width="100%" src={typeof values.logoHorizontal == "string" ? values.logoHorizontal : URL.createObjectURL(values.logoHorizontal)} />}
                                                    <input id="logoHorizontal" name="logoHorizontal" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("logoHorizontal", event.target.files[0]); }} hidden />
                                                </Button>
                                            </Box>
                                            <Box gridColumn="span 1">
                                                <Typography variant='h5' marginBottom="5px">Logo(1080 x 720): </Typography>
                                                <Button component="label" style={{ textAlign: "center", padding: "0" }}>
                                                    {values.logoHorizontalBig && values.logoHorizontalBig !== "" && <img alt='big horiziontal brand logo' width="100%" src={typeof values.logoHorizontalBig == "string" ? values.logoHorizontalBig : URL.createObjectURL(values.logoHorizontalBig)} />}
                                                    <input id="logoHorizontalBig" name="logoHorizontalBig" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("logoHorizontalBig", event.target.files[0]); }} hidden />
                                                </Button>
                                            </Box>
                                            <Box gridColumn="span 1">
                                                <Typography variant='h5' marginBottom="5px">Logo(270 x 400): </Typography>
                                                <Button component="label" style={{ textAlign: "center", padding: "0" }}>
                                                    {values.logoVertical && values.logoVertical !== "" && <img alt='vertical brand logo' width="100%" src={typeof values.logoVertical == "string" ? values.logoVertical : URL.createObjectURL(values.logoVertical)} />}
                                                    <input id="logoVertical" name="logoVertical" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("logoVertical", event.target.files[0]); }} hidden />
                                                </Button>
                                            </Box>



                                        </Box>
                                        <Box m="20px 0px 0px 0px">
                                            <Button type="submit" color="secondary" variant="contained" >
                                                <Typography variant='h5'>Kaydet</Typography>
                                            </Button>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                            <hr style={{ margin: "50px 0 10px 0" }} />
                            <Formik
                                onSubmit={handlePasswordFormSubmit}
                                initialValues={passwordInitialValues}
                                validationSchema={passwordSchema}
                            >
                                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Box display="grid" gap="20px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" marginTop="50px">
                                            <Typography variant='h3' gridColumn="span 4">Şifre Değişikliği</Typography>
                                            {passwordFormLoading && <LinearProgress color='success' sx={{ gridColumn: "span 4" }} />}
                                            {showPasswordAlert &&
                                                <Box gridColumn="span 4" display="grid" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                                                    <Alert severity="error" sx={{ gridColumn: "span 1" }}>Şifre hatalı</Alert>
                                                </Box>
                                            }
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="password"
                                                label="Mevcut Şifre"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.oldPassword}
                                                name="oldPassword"
                                                error={!!touched.oldPassword && !!errors.oldPassword}
                                                helperText={touched.oldPassword && errors.oldPassword}
                                                sx={{ gridColumn: "span 1" }}
                                            />
                                            <Box gridColumn="span 3" />
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="password"
                                                label="Yeni Şifre"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.password}
                                                name="password"
                                                error={!!touched.password && !!errors.password}
                                                helperText={touched.password && errors.password}
                                                sx={{ gridColumn: "span 1" }}
                                            />
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="password"
                                                label="Yeni Şifre Tekrar"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.passwordCheck}
                                                name="passwordCheck"
                                                error={!!touched.passwordCheck && !!errors.passwordCheck}
                                                helperText={touched.passwordCheck && errors.passwordCheck}
                                                sx={{ gridColumn: "span 1" }}
                                            />
                                        </Box>
                                        <Box m="20px 0px 50px 0px">
                                            <Button type="submit" color="secondary" variant="contained" >
                                                <Typography variant='h5'>Değiştir</Typography>
                                            </Button>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                    <Box width="20%" gap="10px" mt="20px">
                        <Box width="100%" p="20px" mb="10px" textAlign="center" display="flex" justifyContent="center" alignItems="center" sx={{ background: colors.greenAccent[600], border: ("1px solid " + colors.grey[500]), borderRadius: "20px" }}>
                            <Box>
                                <Typography variant='h4' width="100%">{user.user_name + " " + user.user_surname}</Typography>
                                <span>{user.email}</span>
                            </Box>
                        </Box>
                        {admins.map((brandAccount) => {
                            if (brandAccount.email === user.email) {
                                return "";
                            }
                            return (
                                <Box key={brandAccount.id} display="flex" justifyContent={user.is_authorized_to_delete === 1 ? "space-between" : "center"} p="20px" mb="10px" textAlign="center" sx={{ background: colors.grey[600], border: ("1px solid " + colors.grey[500]), borderRadius: "20px" }}>
                                    <Box>
                                        <Typography variant='h4' width="100%">{brandAccount.name + " " + brandAccount.surname}</Typography>
                                        <span>{brandAccount.email}</span>
                                    </Box>
                                    {user.is_authorized_to_delete === 1 &&
                                        <IconButton onClick={() => setSelectedAccountId(brandAccount.id)}>
                                            <Delete />
                                        </IconButton>
                                    }
                                </Box>
                            )
                        })}
                        <Box mb="10px" textAlign="center" sx={{ background: colors.grey[600], border: ("1px solid " + colors.grey[500]), borderRadius: "20px" }}>
                            <Button sx={{ height: "100%", width: "100%", padding: "20px", margin: "0" }} onClick={() => setOpenDialog(true)}>
                                <Typography textTransform='capitalize' variant='h4' color={colors.greenAccent[500]}>Yönetici Ekle</Typography>
                            </Button>
                        </Box>
                        {user.webservice_provider === "ideasoft" && <Box>
                            <Typography variant='h2' textAlign="center" mt="30px">Entegrasyonlar</Typography>
                            {user.access_token &&
                                <Box width="100%" p="20px" my="20px" textAlign="center" display="flex" justifyContent="center" alignItems="center" sx={{ background: colors.greenAccent[600], border: ("1px solid " + colors.grey[500]), borderRadius: "20px" }}>
                                    <Box>
                                        <Typography variant='h4' width="100%">Ideasoft</Typography>
                                        <span>Bağlı</span>
                                    </Box>
                                </Box>
                            }
                            {!user.access_token &&
                                <Box my="20px" textAlign="center" sx={{ background: colors.grey[600], border: ("1px solid " + colors.grey[500]), borderRadius: "20px" }}>
                                    <Button sx={{ height: "100%", width: "100%", padding: "20px", margin: "0" }} onClick={() => setOpenIdeasoftDialog(true)}>
                                        <Typography textTransform='capitalize' variant='h4' color={colors.greenAccent[500]}>Ideasoft'u Bağla</Typography>
                                    </Button>
                                </Box>
                            }
                        </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Profile