import { Alert, Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { tokens } from '../../../theme';
import { useContext } from 'react';
import { UserContext } from "../../../context/UserContext";
import axios from "axios";
import { useState } from "react";
import RegisterDialog from "./registerDialog";
import BrandPanel from "../../panels/brandPanel";
import AccessDialog from "./accessDialog";
import ForgetPasswordDialog from "../../../components/ForgetPasswordDialog";
import ProgressDialog from "../../../components/ProgressDialog";

function BrandLogin() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { setToken, token, user, isLoading } = useContext(UserContext);
    const [brandData, setBrandData] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openForgetPasswordDialog, setOpenForgetPasswordDialog] = useState(false);
    const [email, setEmail] = useState("")
    // const [openAccessDialog, setOpenAccessDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [messageType, setMessageType] = useState("");

    const [progressDialogOpen, setProgressDialogOpen] = useState(false);
    if (localStorage.getItem("userType") !== "brand") {
        setToken(null);
        localStorage.setItem("userType", "brand");
    }

    if (user && token) {
        return (<BrandPanel />)
    } else {
        const initialValues = {
            email: "",
            password: ""
        }

        const loginSchema = yup.object().shape({
            email: yup.string().email("Geçersiz eposta").required("zorunlu"),
            password: yup.string().required("zorunlu")
        });

        function generateRandomString(length) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

            for (var i = 0; i < length; i++) {
                var randomIndex = Math.floor(Math.random() * characters.length);
                result += characters.charAt(randomIndex);
            }

            return result;
        }

        const fetchBrand = async (brandName, cb) => {
            axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getBrandWithName?name=${brandName}`).catch((err) => {
                console.log("err: " + err);
                cb(err, null);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    cb(null, response.data.brand);
                } else {
                    cb(null, null);
                }
            });
        }

        const fetchBrandAndCheckData = async (brandName, token) => {
            fetchBrand(brandName, (err, brand) => {
                if (err || !brand) {
                    //TODO: show error
                } else {
                    //check access_token and refresh_token is exists
                    if (brand.access_token && brand.access_token !== "" && brand.refresh_token && brand.refresh_token !== "") {
                        //if exists check user and brand is_active
                        setToken(token)
                        setProgressDialogOpen(false);
                    } else {
                        setTimeout(function () {
                            fetchBrandAndCheckData(brandName, token);
                        }, 3000);
                    }
                }
            });
        }

        const openIdeaSoftAprrovementPage = async (values) => {
            window.open(`${values.website}admin/user/auth?client_id=${values.client_id}&state=${values.ideasoft_code}&response_type=code&redirect_uri=${process.env.REACT_APP_API_URL}api/alcago/brand/ideasoft-result`, "_blank")
        }

        const handleFormSubmit = (values) => {
            setProgressDialogOpen(true);
            axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/login`, values)
                .catch((err) => {
                    setProgressDialogOpen(false);
                    console.log("err: " + err);
                    if (err.response.data.status === 404) {
                        setMessageType("error");
                        setErrorMessage("Kullanıcı bulunamadı");
                    } else if (err.response.data.status === 401) {
                        setMessageType("error");
                        setErrorMessage("Parola hatalı");
                    } else if (err.response.data.status === 406) {
                        if (err.response.data.error.message === "Not approved.") {
                            setMessageType("error");
                            setErrorMessage("Markanız henüz onaylanmadı");
                        } else if (err.response.data.error.message === "Not entegrated.") {
                            // setBrandData({
                            //     brand: {
                            //         name: err.response.data.error.brand_name,
                            //         website: err.response.data.error.brand_website
                            //     }
                            // });
                            // setOpenAccessDialog(true);
                        }
                    }
                })
                .then((tokenResponse) => {
                    if (tokenResponse && tokenResponse.data.success === true && tokenResponse.data.status === 200) {
                        //TODO fetch brand,
                        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getBrandAndAccount?token=${tokenResponse.data.token}`)
                            .catch((err) => {
                                setProgressDialogOpen(false);
                                console.log("err: " + err);
                                setMessageType("error");
                                setErrorMessage("Bir hata oluştu.");
                            })
                            .then((response) => {
                                if (response && response.data && response.data.success === true && response.data.status === 200 && response.data.user) {
                                    //  *if brand's provider is ideasoft
                                    // if (response.data.user.webservice_provider && response.data.user.webservice_provider === "ideasoft") {
                                    //     //  *get access token
                                    //     const user = response.data.user;
                                    //     //
                                    //     axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/checkIdeasoftTokens`, {
                                    //         website: user.website,
                                    //         client_id: user.client_id,
                                    //         client_secret: user.client_secret,
                                    //         refresh_token: user.refresh_token
                                    //     }).catch((err) => {
                                    //         setProgressDialogOpen(false);
                                    //         console.log("err: " + err);
                                    //         setMessageType("error");
                                    //         setErrorMessage("Bir hata oluştu.");
                                    //     })
                                    //         .then((response) => {
                                    //             if (response && response.data && response.data.data) {
                                    //                 if (response.data.data.error === "invalid_grant") {
                                    //                     // *if token is expired
                                    //                     //*set access_token and refresh_token ""
                                    //                     const randomlyGeneratedCode = generateRandomString(16);
                                    //                     axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/updateBrandWithName?name=${user.name}`, { brand: { refresh_token: "", access_token: "", ideasoft_code: randomlyGeneratedCode } }).catch((err) => {
                                    //                         setProgressDialogOpen(false);
                                    //                         console.log("err: " + err);
                                    //                         setMessageType("error");
                                    //                         setErrorMessage("Bir hata oluştu.");
                                    //                     }).then((response) => {
                                    //                         if (response && response.data.success === true && response.data.status === 201) {
                                    //                             // *Open IdeaSoft Aprrovement Page
                                    //                             setTimeout(function () {
                                    //                                 openIdeaSoftAprrovementPage({ website: user.website, client_id: user.client_id, ideasoft_code: randomlyGeneratedCode })
                                    //                             }, 2000);
                                    //                             //*fetch brand countinously
                                    //                             fetchBrandAndCheckData(user.name, tokenResponse.data.token);
                                    //                         }
                                    //                     });
                                    //                 } else {
                                    //                     //TODO save new access and refresh token to mysql
                                    //                     if (response.data.data.access_token && response.data.data.refresh_token) {
                                    //                         axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/updateBrandWithName?name=${user.name}`, { brand: { refresh_token: response.data.data.refresh_token, access_token: response.data.data.access_token } }).catch((err) => {
                                    //                             setProgressDialogOpen(false);
                                    //                             console.log("err: " + err);
                                    //                             setMessageType("error");
                                    //                             setErrorMessage("Bir hata oluştu.");
                                    //                         }).then((response) => {
                                    //                             if (response && response.data.success === true && response.data.status === 201) {
                                    //                                 setToken(tokenResponse.data.token);
                                    //                                 setProgressDialogOpen(false);
                                    //                             } else {
                                    //                                 setProgressDialogOpen(false);
                                    //                             }
                                    //                         });
                                    //                     } else {
                                    //                         setProgressDialogOpen(false);
                                    //                         setMessageType("error");
                                    //                         setErrorMessage("Bir hata oluştu.");
                                    //                     }
                                    //                 }
                                    //             } else {
                                    //                 setProgressDialogOpen(false);
                                    //                 setMessageType("error");
                                    //                 setErrorMessage("Bir hata oluştu.");
                                    //             }
                                    //         });
                                    // } else {
                                    //     setToken(tokenResponse.data.token);
                                    //     setProgressDialogOpen(false);
                                    // }
                                    setToken(tokenResponse.data.token);
                                    setProgressDialogOpen(false);
                                } else {

                                    setProgressDialogOpen(false);
                                }
                            });
                    } else {
                        setProgressDialogOpen(false);
                    }
                });
        }

        const handleDialogClose = () => {
            setOpenDialog(false);
        }

        // const handleDialogCloseWithAccesDialogOpen = (brandData) => {
        //     setBrandData(brandData);
        //     setOpenDialog(false);
        //     setOpenAccessDialog(true);
        // }

        // const handleAccessDialogClose = (is_brand_active) => {
        //     if (is_brand_active) {
        //         setMessageType("success");
        //         setErrorMessage("Entegrasyon tamamlandı tekrar giriş yapabilirsiniz.");
        //         setOpenAccessDialog(false);
        //     } else {
        //         setMessageType("error");
        //         setErrorMessage("Markanız henüz onaylanmadı");
        //         setOpenAccessDialog(false);
        //     }
        // }

        if (isLoading) {
            return (
                <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" />
            )
        }
        return (
            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
                <ProgressDialog open={progressDialogOpen} progress={-1} />
                <Box>
                    <Box p="10px 20px" borderRadius="5px" sx={{ background: colors.grey[600] }}>
                        <Typography variant='h3' color={colors.grey[100]} m="5px 0 20px 0"> Giriş </Typography>
                        {errorMessage !== "" && messageType !== "" && <Alert variant="outlined" severity={messageType} sx={{ mb: "10px" }} onClick={() => { setErrorMessage(""); setMessageType(""); }}>{errorMessage}</Alert>}

                        <Box>
                            <Formik
                                onSubmit={handleFormSubmit}
                                initialValues={initialValues}
                                validationSchema={loginSchema}
                            >
                                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Box>
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="email"
                                                label="Eposta"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                    handleChange(e)
                                                }}
                                                value={values.email}
                                                name="email"
                                                error={!!touched.email && !!errors.email}
                                                helperText={touched.email && errors.email}
                                            />
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="password"
                                                label="Şifre"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.password}
                                                name="password"
                                                error={!!touched.password && !!errors.password}
                                                helperText={touched.password && errors.password}
                                                sx={{ margin: "10px 0 0 0" }}
                                            />
                                        </Box>
                                        <Box display="flex" justifyContent="end" m="20px 10px 5px 0">
                                            <Button type="submit" color="secondary" variant="contained">
                                                Giriş
                                            </Button>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="top" px="10px">
                        <Typography variant="h6" textAlign="center" mt="10px" onClick={() => {
                            if (email && email.indexOf("@") > 0 && email.indexOf(".") > 0) {
                                axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/validateUserData`, { email: email }).catch((err) => {
                                    console.log("err: " + err);
                                    setMessageType("error");
                                    setErrorMessage("E-posta adresi bulunamadı.");
                                }).then((response) => {
                                    if (response && response.data.success === true && response.data.status === 200 && response.data.isValid === false) {
                                        setOpenForgetPasswordDialog(true)
                                    } else {
                                        setMessageType("error");
                                        setErrorMessage("E-posta adresi bulunamadı.");
                                    }
                                });
                            } else {
                                setMessageType("error");
                                setErrorMessage("Lütfen geçerli bir e-posta adresi girin.");
                            }
                        }} sx={{ cursor: "pointer" }}>Şifremi Unuttum</Typography>
                        <Typography variant="h6" textAlign="center" mt="10px" onClick={() => setOpenDialog(true)} sx={{ cursor: "pointer" }}>Üye değil misiniz?</Typography>
                    </Box>
                    <RegisterDialog
                        open={openDialog}
                        handleClose={handleDialogClose}
                        // handleCloseWithAccesDialogOpen={handleDialogCloseWithAccesDialogOpen}
                        type="new" />
                    {openForgetPasswordDialog && email &&
                        <ForgetPasswordDialog open={openForgetPasswordDialog} handleClose={() => setOpenForgetPasswordDialog(false)} email={email} type="brand" />
                    }
                    {/* {brandData && <AccessDialog open={openAccessDialog} handleClose={handleAccessDialogClose} brandData={brandData} />} */}
                </Box>
            </Box>
        )
    }
}

export default BrandLogin;